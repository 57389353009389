<template>
    <div>
        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible" class="mapdialog dialog-lg">
            <div class="mb15" v-if="isedits">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <!-- 详情 -->
            <div class="view-card-info" v-if="viewshow">
                <el-row class="mb0">
                    <el-col :span="24">
                        <el-descriptions :column="4" class="mb0">
                            <el-descriptions-item label="项目(法人)单位">{{pagedata.xmdw}}</el-descriptions-item>
                            <el-descriptions-item label="地址">{{pagedata.dz}}</el-descriptions-item>
                            <el-descriptions-item label="联系人">{{pagedata.lxr}}</el-descriptions-item>
                            <el-descriptions-item label=" 联系电话">{{pagedata.lxdh}}</el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
                <el-row class="mycard mb0">
                    <el-col :span="14">
                        <table class="table">
                            <tr>
                                <th width="141">项目名称</th>
                                <td>{{pagedata.xmmc}}</td>
                                <th width="141">项目编码</th>
                                <td>{{pagedata.xmbm}}</td>
                                <th width="141">拟开工日期</th>
                                <td>{{setTimes(pagedata.nkgrq)}}</td>
                            </tr>
                            <tr>
                                <th>建设地点</th>
                                <td>{{pagedata.jsdd}}</td>
                                <th>建设详细地址</th>
                                <td colspan="3">{{pagedata.jsxxdz}}</td>
                            </tr>
                            <tr>
                                <th>建设地点详情</th>
                                <td colspan="5">{{pagedata.jsddxq}}</td>
                            </tr>
                        </table>

                        <table class="table">
                            <tr>
                                <th width="141">审批事项（要件）</th>
                                <th width="80">批复单位</th>
                                <th width="204">批准文号和文件标题</th>
                                <th>总建设规模<br />（平方米）</th>
                                <th>总投资<br />（万元）</th>
                                <th>批复主要内容</th>
                                <th>附件</th>
                            </tr>
                            <tr v-for="item in pagedata.checkList" :key="item.id">
                                <td align="center">{{item.spsx}}</td>
                                <td>{{item.pfdw}}</td>
                                <td>
                                    <el-link type="primary">{{item.pzwhhwjbt}}</el-link>
                                </td>
                                <td align="center">{{item.zjsgm}}</td>
                                <td align="center">{{item.ztz}}</td>
                                <td>{{item.pfysnr}}</td>
                                <td align="center">
                                    <el-popover trigger="hover" placement="top" v-if="item.fjlj.length > 0">
                                        <ul class="fj-list">
                                            <li v-for="(v,i) in item.fjmc" :key="i">
                                                <el-link :href="baseUrl+item.fjlj[i]" type="primary">{{v}}</el-link>
                                            </li>
                                        </ul>
                                        <div slot="reference" class="name-wrapper">
                                            <el-tag :type="item.fjlj.length>0?'success':'danger'" size="small">已上传{{item.fjlj.length}}个</el-tag>
                                        </div>
                                    </el-popover>
                                </td>
                            </tr>
                            <tr v-if="pagedata.checkList && pagedata.checkList.length <= 0">
                                <td colspan="12" style="border:0">
                                    <el-empty description="暂无数据"></el-empty>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="10">
                        <table class="table">
                            <tr>
                                <th>
                                    {{setTimes(pagedata.zprq)}}照片
                                    <!-- 20<span style="width:50px;display:inline-block"></span>年<span style="width:50px;display:inline-block"></span>月<span style="width:50px;display:inline-block"></span> -->
                                </th>
                            </tr>
                            <tr >
                                <td v-if="pagedata.zpdz"><img :src="baseUrl+pagedata.zpdz" width="100%" class="ps-viwe-img" /></td>
                                <td v-else colspan="12">
                                    <el-empty description="暂无照片"></el-empty>
                                </td>
                            </tr>

                            <tr></tr>
                        </table>

                        <table class="table">
                            <tr>
                                <th colspan="6">实施信息</th>
                            </tr>
                            <tr>
                                <td width="100">实际开工时间</td>
                                <td colspan="2" width="179">{{setTimes(pagedata.sjkgsj)}}</td>
                                <td colspan="2">实际竣工时间</td>
                                <td>{{setTimes(pagedata.sjjgsj)}}</td>
                            </tr>
                            <tr>
                                <td>招标形式</td>
                                <td width="88">公开、邀请、直接发包</td>
                                <td width="122">中标单位</td>
                                <td colspan="2">形象进度</td>
                                <td width="90">完成投资（万元）</td>
                            </tr>
                            <tr>
                                <td>设计招标</td>
                                <td>{{pagedata.sjzbxs}}</td>
                                <td>{{pagedata.sjzbzbdw}}</td>
                                <td colspan="2" rowspan="4">
                                    {{pagedata.xxjd}}
                                </td>
                                <td rowspan="4">{{pagedata.wctz}}</td>
                            </tr>
                            <tr>
                                <td>监理招标</td>
                                <td>{{pagedata.jlzbxs}}</td>
                                <td>{{pagedata.jlzbzbdw}}</td>
                            </tr>
                            <tr>
                                <td>施工招标</td>
                                <td>{{pagedata.sgzbxs}}</td>
                                <td>{{pagedata.sgzbzbdw}}</td>
                            </tr>
                            <tr>
                                <td>设备采购</td>
                                <td>{{pagedata.sbcgzbxs}}</td>
                                <td>{{pagedata.sbcgzbdw}}</td>
                            </tr>
                            <tr>
                                <td>其他</td>
                                <td>{{pagedata.qtzbxs}}</td>
                                <td>{{pagedata.qtzbdw}}</td>
                                <td width="62">报告期</td>
                                <td colspan="2" width="189">{{pagedata.bgq}}</td>
                            </tr>
                            <tr>
                                <td>责任单位</td>
                                <td colspan="5">{{pagedata.zrdw}}</td>
                            </tr>
                            <tr>
                                <td>日常监管人</td>
                                <td width="62">{{pagedata.rcjgr}}</td>
                                <td colspan="2">责任单位联系人电话</td>
                                <td colspan="2">{{pagedata.zrdwlxrdh}}</td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="24">
                        <!--投资来源-->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th rowspan="2">年度计划</th>
                                    <th colspan="6">投资来源（万元）</th>
                                    <th rowspan="2" width="87">建设性质</th>
                                    <th rowspan="2">规划（批复）建设内容</th>
                                    <th rowspan="2">实际完成情况及需要说明的事宜</th>
                                </tr>
                                <tr>
                                    <th align="center">中央车购税</th>
                                    <th>省转移支付</th>
                                    <th>土地折价</th>
                                    <th>地方优惠政策折价</th>
                                    <th align="center">企业自筹</th>
                                    <th align="center">其他投资</th>
                                </tr>
                            </thead>
                            <tr v-for="item in pagedata.yearplanList" :key="item.id">
                                <td align="center">{{item.ndjh}}</td>
                                <td align="center">{{item.zycgs}}</td>
                                <td align="center">{{item.szyzf}}</td>
                                <td align="center">{{item.tdzj}}</td>
                                <td align="center">{{item.dfyhzczj}}</td>
                                <td align="center">{{item.qyzc}}</td>
                                <td align="center">{{item.qttz}}</td>
                                <td>{{item.jsxz}}</td>
                                <td>{{item.ghpfjsnr}}</td>
                                <td>{{item.sjwcqkjxysmdsy}}</td>
                            </tr>
                            <tr v-if="pagedata.yearplanList && pagedata.yearplanList.length <= 0">
                                <td colspan="12" style="border:0">
                                    <el-empty description="暂无数据"></el-empty>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="10">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th width="220">总用地规模（平方米）</th>
                                    <th>{{pagedata.tccydgm+pagedata.dcjyqydgm+pagedata.cqdlydgm+pagedata.lhydgm+pagedata.jdzmjydgm}}</th>

                                    <th width="220">总投资及资金来源(万元)</th>
                                    <th>{{pagedata.zycgs+pagedata.szyzf+pagedata.tdzj+pagedata.dfyhzczj+pagedata.qyzc }}</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>1、停车场</td>
                                <td align="center">{{pagedata.tccydgm}}</td>
                                <td>1、中央车购税</td>
                                <td align="center">{{pagedata.zycgs}}</td>
                            </tr>
                            <tr>
                                <td>2、堆场、交易区</td>
                                <td align="center">{{pagedata.dcjyqydgm}}</td>
                                <td>2、省转移支付</td>
                                <td align="center">{{pagedata.szyzf}}</td>
                            </tr>
                            <tr>
                                <td>3、厂区道路</td>
                                <td align="center">{{pagedata.cqdlydgm}}</td>
                                <td>3、土地折价</td>
                                <td align="center">{{pagedata.tdzj}}</td>
                            </tr>
                            <tr>
                                <td>4、绿化</td>
                                <td align="center">{{pagedata.lhydgm}}</td>
                                <td>4、地方优惠政策折价</td>
                                <td align="center">{{pagedata.dfyhzczj}}</td>
                            </tr>
                            <tr>
                                <td>5、基底总面积</td>
                                <td align="center">{{pagedata.jdzmjydgm}}</td>
                                <td>5、企业自筹</td>
                                <td align="center">{{pagedata.qyzc}}</td>
                            </tr>

                            <tr height="26">
                                <td>实际年度平均日旅客发送量<small>（人次/日）</small></td>
                                <td>{{pagedata.sjndpjrlkfsl}}</td>
                                <td>实际年度日均发车班次<small>（班次/日）</small></td>
                                <td>{{pagedata.sjndrjfcbc}}</td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="14">
                        <table class="table">
                            <tr>
                                <th colspan="5">货运站总建设规划（平方米）</th>
                                <th colspan="3">{{pagedata.hyzzjsgh}}</th>
                            </tr>
                            <tr>
                                <th colspan="8" align="center">其中：</th>
                            </tr>
                            <tr>
                                <td width="130">一、站房</td>
                                <td align="center">{{pagedata.zfjsgh}}</td>
                                <td>二、生产辅助用房</td>
                                <td align="center">{{pagedata.scfzyfjsgh}}</td>
                                <td>三、生活辅助用房</td>
                                <td align="center">{{pagedata.shfzyfjsgh}}</td>
                                <td>四、其他用房</td>
                                <td align="center">{{pagedata.qtyfjsgh}}</td>

                            </tr>
                            <tr>
                                <td>1、货运交易厅</td>
                                <td align="center">{{pagedata.hyjytjsgh}}</td>
                                <td>1、零担间</td>
                                <td align="center">{{pagedata.ldjjsgh}}</td>
                                <td>1、汽车旅馆</td>
                                <td align="center">{{pagedata.qclgjsgh}}</td>
                                <td>1、设备用房</td>
                                <td align="center">{{pagedata.sbyfjsgh}}</td>
                            </tr>
                            <tr>
                                <td>2、信息交易厅</td>
                                <td align="center">{{pagedata.xxjytjsgh}}</td>
                                <td>2、零修间</td>
                                <td align="center">{{pagedata.lxjjsgh}}</td>
                                <td>2、改建汽车旅馆</td>
                                <td align="center">{{pagedata.gjqclgjsgh}}</td>
                                <td>2、车库</td>
                                <td align="center">{{pagedata.cejsgh}}</td>
                            </tr>
                            <tr>
                                <td>3、货物零担间</td>
                                <td align="center">{{pagedata.hwtdjjsgh}}</td>
                                <td>3、锅炉房及变电所</td>
                                <td align="center">{{pagedata.glfjbdsjsgh}}</td>
                                <td>3、物业管理</td>
                                <td align="center">{{pagedata.wygljsgh}}</td>
                                <td>3、门卫室</td>
                                <td align="center">{{pagedata.mwsjsgh}}</td>
                            </tr>
                            <tr>
                                <td>4、仓库</td>
                                <td align="center">{{pagedata.ckjsgh}}</td>
                                <td></td>
                                <td align="center"></td>
                                <td>
                                    <!-- 4、... -->
                                </td>
                                <td align="center"></td>
                                <td></td>
                                <td align="center"></td>
                            </tr>

                        </table>
                    </el-col>
                    <el-col :span="24">
                        <table class="table">
                            <tr>
                                <th width="141">备注</th>
                                <td>
                                    {{pagedata.bz}}
                                </td>
                            </tr>
                        </table>
                    </el-col>
                </el-row>
                <el-row class="mb0">
                    <el-col :span="24">
                        <el-descriptions :column="5" class="mb0">
                            <el-descriptions-item label="项目责任人">{{pagedata.xmzrr}}</el-descriptions-item>
                            <el-descriptions-item label="联系电话">{{pagedata.xmzrrlxdh}}</el-descriptions-item>
                            <el-descriptions-item label="项目联系人">{{pagedata.xmlxr}}</el-descriptions-item>
                            <el-descriptions-item label="联系电话">{{pagedata.xmlxrlxdh}}</el-descriptions-item>
                            <el-descriptions-item label="邮箱">{{pagedata.yx}}</el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
            </div>
            <!-- 表单 -->
            <div class="edit-card-info" v-if="editshow&&isedits" v-loading="vloading" element-loading-text="正在上传中，请等待" element-loading-spinner="el-icon-loading">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                <el-row class="mb0">
                    <el-col :span="24">
                        <el-descriptions :column="4" border>
                            <el-descriptions-item label="项目(法人)单位">
                                <el-input v-model="formdata.xmdw"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="地址">
                                <el-input v-model="formdata.dz"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系人">
                                <el-input v-model="formdata.lxr"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label=" 联系电话">
                                <el-input v-model="formdata.lxdh"></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
                <el-row class="mycard mb0">
                    <el-col :span="16">
                        <table class="table">
                            <tr>
                                <th width="141">项目名称</th>
                                <td>
                                    <!-- <el-input v-model="formdata.ZHKYSNMC"></el-input> -->
                                    {{dialogDetails.XMMC}}
                                </td>
                                <th width="141">项目编码</th>
                                <td>
                                    <el-input v-model="formdata.xmbm"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th width="141">拟开工日期</th>
                                <td>
                                    <el-input v-model="formdata.nkgrq"></el-input>
                                </td>

                                <th>建设地点</th>
                                <td>
                                    <el-input v-model="formdata.jsdd"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设详细地址</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.jsxxdz"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设地点详情</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.jsddxq"></el-input>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="8">
                        <table class="table">
                            <tr>
                                <th>
                                    <el-date-picker type="date" placeholder="选择日期" v-model="formdata.zprq" style="width:150px"></el-date-picker>
                                    照片

                                    <label for="fileInput" class="fright">
                                        <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传文件</i>
                                    </label>
                                    <input type="file" v-show="false" accept=".jpg,.png,.jpeg" name="file" ref="file" id="fileInput" @change="onSubmit($event,'zpdz')" />
                                </th>
                            </tr>
                            <tr height="135">
                                <td>
                                    <div>
                                        <div class="ps-viwe">
                                            <a :href="baseUrl+formdata.zpdz" target="_black"><img :src="baseUrl+formdata.zpdz" alt="" srcset=""></a>
                                        </div>
                                        <!-- src="http://121.89.201.182:9992/Upload/Tables/Plan_passenger_card/202111240852008007/微信图片_20211022095710.jpg" -->
                                    </div>
                                    <!-- <el-upload class="upload-demo" action="123123" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList1" :limit="1" list-type="picture">
                                            <el-button size="mini" type="primary">点击上传</el-button>
                                            <span slot="tip" class="el-upload__tip">&nbsp;&nbsp;只能上传jpg/png文件，且不超过500kb</span>
                                        </el-upload> -->

                                </td>
                            </tr>
                            <tr></tr>
                        </table>
                    </el-col>
                    <el-col :span="24">
                        <table class="table">
                            <tr>
                                <th width="150">审批事项（要件）</th>
                                <th width="200">批复单位</th>
                                <th>批准文号和文件标题</th>

                                <th width="100">
                                    总建设规模<br />
                                    （平方米）
                                </th>
                                <th width="100">
                                    总投资<br />
                                    （万元）
                                </th>
                                <th width="150">批复主要内容</th>
                                <th width="80">附件</th>
                                <th width="80">操作</th>
                            </tr>
                            <tr v-for="(item,index) in formdata.checkList" :key="index">
                                <td>{{item.spsx}}</td>
                                <td>
                                    <el-input v-model="item.pfdw" placeholder="批复单位"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="item.pzwhhwjbt" placeholder="批准文号和文件标题"></el-input>
                                </td>

                                <td>
                                    <el-input v-model="item.zjsgm" placeholder="总建设规模"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="item.ztz" placeholder="总投资"></el-input>
                                </td>
                                <td>
                                    <el-input v-model="item.pfysnr" placeholder="批复主要内容"></el-input>
                                </td>
                                <td>
                                    <el-popover trigger="hover" placement="top" v-if="item.fjlj.length > 0">
                                        <ul class="fj-list">
                                            <li v-for="(v,i) in item.fjmc" :key="i">
                                                <el-link :href="baseUrl+item.fjlj[i]" type="primary">{{v}}</el-link>
                                                <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>
                                            </li>
                                        </ul>

                                        <div slot="reference" class="name-wrapper">
                                            <el-tag :type="item.fjlj.length>0?'success':'danger'" size="small">已上传{{item.fjlj.length}}个</el-tag>
                                        </div>
                                    </el-popover>
                                </td>
                                <td>
                                    <!-- <el-upload class="upload-demo" action="123123" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                                            <el-button size="small" type="primary">上传附件</el-button>
                                        </el-upload> -->
                                    <div>
                                        <label :for="'fileInput_'+index">
                                            <i aria-hidden="true" class="upload-demo cursor el-button  el-button--primary el-button--small">上传附件</i>
                                        </label>
                                        <input type="file" v-show="false" accept="*" name="file" ref="file" multiple="multiple" :id="'fileInput_'+index" @change="onSubmit($event,index)" />
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </el-col>
                    <el-col :span="24">

                        <table class="table">
                            <tr>
                                <th colspan="7">实施信息</th>
                            </tr>
                            <tr>
                                <td width="10%">实际开工时间</td>
                                <td width="14%">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="formdata.sjkgsj" style="width: 100%"></el-date-picker>
                                </td>
                                <td width="10%">实际竣工时间</td>
                                <td width="14%">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="formdata.sjjgsj" style="width: 100%"></el-date-picker>
                                </td>
                                <td width="10%">
                                    <!-- <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top-start">
                                            <el-button style="border:0;"> 完成投资（万元）</el-button>
                                        </el-tooltip> -->
                                    完成投资（万元）
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="完成投资（万元）" type="number" v-model="formdata.wctz"></el-input>

                                </td>
                            </tr>
                            <tr>
                                <td>招标形式</td>
                                <td width="150">公开、邀请、直接发包</td>
                                <td colspan="2">中标单位</td>
                                <td colspan="3">形象进度</td>
                            </tr>
                            <tr>
                                <td>设计招标</td>
                                <td>
                                    <el-select v-model="formdata.sjzbxs" placeholder="请选择招标形式">
                                        <el-option value="公开">公开</el-option>
                                        <el-option value="邀请">邀请</el-option>
                                        <el-option value="直接发包">直接发包</el-option>
                                    </el-select>
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="中标单位" v-model="formdata.sjzbzbdw"></el-input>
                                </td>
                                <td colspan="3" rowspan="4">
                                    <el-input type="textarea" placeholder="形象进度" v-model="formdata.xxjd" :rows="8"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>监理招标</td>
                                <td>
                                    <el-select v-model="formdata.jlzbxs" placeholder="请选择招标形式">
                                        <el-option value="公开">公开</el-option>
                                        <el-option value="邀请">邀请</el-option>
                                        <el-option value="直接发包">直接发包</el-option>
                                    </el-select>
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="中标单位" v-model="formdata.jlzbzbdw"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>施工招标</td>
                                <td>
                                    <el-select v-model="formdata.sgzbxs" placeholder="请选择招标形式">
                                        <el-option value="公开">公开</el-option>
                                        <el-option value="邀请">邀请</el-option>
                                        <el-option value="直接发包">直接发包</el-option>
                                    </el-select>
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="中标单位" v-model="formdata.sgzbzbdw"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>设备采购</td>
                                <td>
                                    <el-select v-model="formdata.sbcgzbxs" placeholder="请选择招标形式">
                                        <el-option value="公开">公开</el-option>
                                        <el-option value="邀请">邀请</el-option>
                                        <el-option value="直接发包">直接发包</el-option>
                                    </el-select>
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="中标单位" v-model="formdata.sbcgzbdw"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>其他</td>
                                <td>
                                    <el-select v-model="formdata.qtzbxs" placeholder="请选择招标形式">
                                        <el-option value="公开">公开</el-option>
                                        <el-option value="邀请">邀请</el-option>
                                        <el-option value="直接发包">直接发包</el-option>
                                    </el-select>
                                </td>
                                <td colspan="2">
                                    <el-input placeholder="中标单位" v-model="formdata.qtzbdw"></el-input>
                                </td>
                                <td width="62">报告期</td>
                                <td colspan="2">
                                    <el-input placeholder="报告期" v-model="formdata.bgq"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>责任单位</td>
                                <td colspan="2">
                                    <el-input placeholder="责任单位" v-model="formdata.zrdw"></el-input>
                                </td>
                                <td>日常监管人</td>
                                <td>
                                    <el-input placeholder="日常监管人" v-model="formdata.rcjgr"></el-input>
                                </td>
                                <td>责任单位联系人电话</td>
                                <td>
                                    <el-input placeholder="责任单位联系人电话" v-model="formdata.zrdwlxrdh"></el-input>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="24">
                        <!--投资来源-->
                        <table class="table">
                            <thead>
                                <tr>
                                    <th rowspan="2" width="88">年度计划</th>
                                    <th colspan="6">投资来源（万元）</th>
                                    <th rowspan="2" width="87">建设性质</th>
                                    <th rowspan="2">规划（批复）建设内容</th>
                                    <th rowspan="2">实际完成情况及需要说明的事宜</th>
                                    <th width="40" rowspan="2">操作</th>
                                </tr>
                                <tr>
                                    <th align="center" width="100">中央车购税</th>
                                    <th width="100">省转移支付</th>
                                    <th width="100">土地折价</th>
                                    <th width="110">地方优惠政策折价</th>
                                    <th align="center" width="100">企业自筹</th>
                                    <th align="center" width="100">其他投资</th>
                                </tr>
                            </thead>
                            <tr v-for="(item,index) in formdata.yearplanList" :key="index">
                                <td>
                                    <el-input placeholder="年度计划" v-model="item.ndjh"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="中央车购税" type="number" v-model="item.zycgs"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="省转移支付" type="number" v-model="item.szyzf"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="土地折价" type="number" v-model="item.tdzj"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="地方优惠政策折价" type="number" v-model="item.dfyhzczj"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="企业自筹" type="number" v-model="item.qyzc"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="其他投资" type="number" v-model="item.qttz"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="建设性质" v-model="item.jsxz"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="规划（批复）建设内容" v-model="item.ghpfjsnr"></el-input>
                                </td>
                                <td>
                                    <el-input placeholder="实际完成情况及需要说明的事宜" v-model="item.sjwcqkjxysmdsy"></el-input>
                                </td>
                                <td align="center">
                                    <el-button type="danger" @click="removeyearplanList(index)" icon="el-icon-delete" size="mini" circle></el-button>
                                </td>
                            </tr>
                            <tr>
                                <td align="right" colspan="12" style="padding-right:11px">
                                    <el-button type="success" icon="el-icon-plus" size="mini" @click="addyearplanList()" circle></el-button>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="24">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="3">总用地规模（平方米）</th>
                                    <th>
                                        {{getZyhgm()}}
                                    </th>
                                    <th colspan="3">总投资及资金来源(万元)</th>
                                    <th>
                                        {{getZtzjzjly()}}
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td width="12%">1、停车场</td>
                                <td width="13%">
                                    <el-input placeholder="" v-model="formdata.tccydgm"></el-input>
                                </td>
                                <td width="12%">2、堆场、交易区</td>
                                <td width="13%">
                                    <el-input placeholder="" v-model="formdata.dcjyqydgm"></el-input>
                                </td>
                                <td width="12%">1、中央车购税</td>
                                <td width="13%">
                                    <el-input placeholder="" v-model="formdata.zycgs"></el-input>
                                </td>
                                <td width="12%">2、省转移支付</td>
                                <td width="13%">
                                    <el-input placeholder="" v-model="formdata.szyzf"></el-input>
                                </td>
                            </tr>

                            <tr>
                                <td>3、厂区道路</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.cqdlydgm"></el-input>
                                </td>
                                <td>4、绿化</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.lhydgm"></el-input>
                                </td>
                                <td>3、土地折价</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.tdzj"></el-input>
                                </td>
                                <td>4、地方优惠政策折价</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.dfyhzczj"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>5、基底总面积</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.jdzmjydgm"></el-input>
                                </td>
                                <td></td>
                                <td></td>

                                <td>5、企业自筹</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.qyzc"></el-input>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr>
                                <td colspan="3">实际年度平均日旅客发送量（人次/日）</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.sjndpjrlkfsl"></el-input>
                                </td>
                                <td colspan="3">实际年度日均发车班次（班次/日）</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.sjndrjfcbc"></el-input>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                    <el-col :span="24">
                        <table class="table">
                            <tr>
                                <th colspan="5">货运站总建设规划（平方米）</th>
                                <th colspan="3">
                                    <el-input placeholder="" v-model="formdata.hyzzjsgh"></el-input>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="8" style="line-height: 27px">其中：</th>
                            </tr>
                            <tr>
                                <td width="15%">一、站房</td>
                                <td width="10%">
                                    <!-- <el-input placeholder=""></el-input> -->
                                    {{getZf()}}
                                </td>
                                <td width="15%">二、生产辅助用房</td>
                                <td width="10%">
                                    {{getScfzyf()}}
                                    <!-- <el-input placeholder=""></el-input> -->
                                </td>
                                <td width="15%">三、生活辅助用房</td>
                                <td width="10%">
                                    {{getShfzyf()}}
                                    <!-- <el-input placeholder=""></el-input> -->
                                </td>
                                <td width="15%">四、其他用房</td>
                                <td width="10%">
                                    {{getQtyf()}}
                                    <!-- <el-input placeholder=""></el-input> -->
                                </td>
                            </tr>
                            <tr>
                                <td>1、货运交易厅</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.hyjytjsgh"></el-input>
                                </td>
                                <td>1、零担间</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.ldjjsgh"></el-input>
                                </td>
                                <td>1、汽车旅馆</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.qclgjsgh"></el-input>
                                </td>
                                <td>1、设备用房</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.sbyfjsgh"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>2、信息交易厅</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.xxjytjsgh"></el-input>
                                </td>
                                <td>2、零修间</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.lxjjsgh"></el-input>
                                </td>
                                <td>2、改建汽车旅馆</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.gjqclgjsgh"></el-input>
                                </td>
                                <td>2、车库</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.cejsgh"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>3、货物零担间</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.hwtdjjsgh"></el-input>
                                </td>
                                <td>3、锅炉房及变电所</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.glfjbdsjsgh"></el-input>
                                </td>
                                <td>3、物业管理</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.wygljsgh"></el-input>
                                </td>
                                <td>3、门卫室</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.mwsjsgh"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td>4、仓库</td>
                                <td>
                                    <el-input placeholder="" v-model="formdata.ckjsgh"></el-input>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                        </table>
                    </el-col>
                    <el-col :span="24">
                        <table class="table">
                            <tr>
                                <th width="141">备注</th>

                                <td>
                                    <el-input type="textarea" placeholder="" v-model="formdata.bz"></el-input>
                                </td>
                            </tr>
                        </table>
                    </el-col>
                </el-row>
                <el-row class="mb0">
                    <el-col :span="24">
                        <el-descriptions :column="5" class="mb0" border>
                            <el-descriptions-item label="项目责任人">
                                <el-input placeholder="" v-model="formdata.xmzrr"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系电话">
                                <el-input placeholder="" v-model="formdata.xmzrrlxdh"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="项目联系人">
                                <el-input placeholder="" v-model="formdata.xmlxr"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系电话">
                                <el-input placeholder="" v-model="formdata.xmlxrlxdh"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="邮箱">
                                <el-input placeholder="" v-model="formdata.yx"></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["data", "round","isedits"],
    name: "",
    components: {},
    data() {
        return {
            vloading: false,
            baseUrl: "http://82.156.50.94:8002/",
            formdata: {},
            pagedata: {},
            isedit: false,
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
            dialogTableVisible: false,
            dialogTableVisible1: false,
            dialogTableVisible2: false,
            dialogTableVisible4: false,
            dialogTableVisible6: false,
            dialogTableVisible7: false,
            dialogDetails: {},
            activecollapse: "1",
        };
    },
    mounted() {
        this.dialogDetails = this.data;
        console.log(this.dialogDetails);
        this.setData();
        this.getData();
    },
    methods: {
        setTimes(time) {
            if (!time) return time;
            let dt = new Date(time);
            let yyyy = dt.getFullYear();
            let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
            let dd = dt.getDate().toString().padStart(2, "0");
            return yyyy + "-" + MM + "-" + dd;
        },
        getZf() {
            return (
                parseFloat(this.formdata.hyjytjsgh || 0) +
                parseFloat(this.formdata.xxjytjsgh || 0) +
                parseFloat(this.formdata.hwtdjjsgh || 0) +
                parseFloat(this.formdata.ckjsgh || 0)
            );
        },
        getScfzyf() {
            return (
                parseFloat(this.formdata.ldjjsgh || 0) +
                parseFloat(this.formdata.lxjjsgh || 0) +
                parseFloat(this.formdata.glfjbdsjsgh || 0)
            );
        },
        getShfzyf() {
            return (
                parseFloat(this.formdata.qclgjsgh || 0) +
                parseFloat(this.formdata.gjqclgjsgh || 0) +
                parseFloat(this.formdata.wygljsgh || 0)
            );
        },
        getQtyf() {
            return (
                parseFloat(this.formdata.sbyfjsgh || 0) +
                parseFloat(this.formdata.cejsgh || 0) +
                parseFloat(this.formdata.mwsjsgh || 0)
            );
        },
        getZyhgm() {
            return (
                parseFloat(this.formdata.tccydgm || 0) +
                parseFloat(this.formdata.dcjyqydgm || 0) +
                parseFloat(this.formdata.cqdlydgm || 0) +
                parseFloat(this.formdata.lhydgm || 0) +
                parseFloat(this.formdata.jdzmjydgm || 0)
            );
        },
        getZtzjzjly() {
            return (
                parseFloat(this.formdata.zycgs || 0) +
                parseFloat(this.formdata.szyzf || 0) +
                parseFloat(this.formdata.tdzj || 0) +
                parseFloat(this.formdata.dfyhzczj || 0) +
                parseFloat(this.formdata.qyzc || 0)
            );
        },
        removeyearplanList(index) {
            console.log(index);
            this.formdata.yearplanList.splice(index, 1);
        },
        addyearplanList() {
            var data = {
                id: "00000000-0000-0000-0000-000000000000",
                ndjh: "",
                zycgs: 0,
                szyzf: 0,
                tdzj: 0,
                dfyhzczj: 0,
                qyzc: 0,
                qttz: 0,
                jsxz: "",
                ghpfjsnr: "",
                sjwcqkjxysmdsy: "",
                kpid: "00000000-0000-0000-0000-000000000000",
            };
            this.formdata.yearplanList.push(data);
        },
        addcheckList() {
            var arr = [
                "城市发展规划",
                "选址意见书",
                "用地预审意见",
                "环境影响评价审批文件",
                "节能审查意见",
                "可行性研究报告批复（或核准或备案）",
                "建设用地规划许可证",
                "建设工程规划许可证",
                "初步设计文件批复（或核准或备案）",
                "资金申请报告",
                "建设用地批准书",
            ];
            arr.forEach((item) => {
                var data = {
                    id: "00000000-0000-0000-0000-000000000000",
                    spsx: item,
                    pfdw: "",
                    fjmc: [],
                    fjlj: [],
                    pzwhhwjbt: "",
                    zjsgm: 0,
                    ztz: 0,
                    pfysnr: "",
                    kpid: "00000000-0000-0000-0000-000000000000",
                };
                this.formdata.checkList.push(data);
            });
        },
        getData() {
            this.http
                .post(
                    "/api/Plan_freight_card/getItemByHysnid?hysnid=" +
                        this.dialogDetails.ID,
                    {}
                )
                .then((res) => {
                    this.pagedata = JSON.parse(JSON.stringify(res.data));
                    this.formdata = JSON.parse(JSON.stringify(res.data));
                    if (this.formdata.checkList.length <= 0) {
                        this.formdata.checkList = [];
                        this.addcheckList();
                    } else {
                        this.formdata.checkList.forEach((item) => {
                            item.fjlj = item.fjlj ? item.fjlj.split(";") : [];
                            item.fjmc = item.fjmc ? item.fjmc.split(";") : [];
                        });
                    }
                    if (this.formdata.yearplanList.length <= 0) {
                        this.formdata.yearplanList = [];
                        this.addyearplanList();
                    }
                    this.pagedata.checkList.forEach((item) => {
                        item.fjlj = item.fjlj ? item.fjlj.split(";") : [];
                        item.fjmc = item.fjmc ? item.fjmc.split(";") : [];
                    });
                    console.log(this.pagedata);
                    console.log(this.formdata);
                });
        },
        onSubmit(e, type) {
            this.vloading = true;
            let file = e.target.files;
            let form = new FormData();
            let arrs = [];
            console.log(type);
            file.forEach((element) => {
                form.append("fileInput", element);
                arrs.push(element.name);
            });
            this.http
                .post("/api/Plan_passenger_card/upload", form)
                .then((res) => {
                    this.$message(res.message);
                    if (res.status) {
                        if (type == "zpdz") {
                            this.formdata.zpdz = res.data + file[0].name;
                        } else {
                            this.formdata.checkList[type].fjmc = arrs;
                            arrs.forEach((v) => {
                                this.formdata.checkList[type].fjlj.push(
                                    res.data + v
                                );
                            });
                        }
                    }
                    this.vloading = false;
                });
        },
        submit() {
            console.log(this.formdata);
            var url = "/api/Plan_freight_card/UpdatePlanFreightCard";

            if (
                this.formdata.id == "00000000-0000-0000-0000-000000000000" ||
                !this.formdata.id
            ) {
                url = "/api/Plan_freight_card/AddPlanFreightCard";
            }
            this.formdata.checkList.forEach((item) => {
                if (item.fjlj) {
                    item.fjlj = item.fjlj.join(";");
                } else {
                    item.fjlj = "";
                }

                if (item.fjmc) {
                    item.fjmc = item.fjmc.join(";");
                } else {
                    item.fjmc = "";
                }
            });
            this.formdata.hysnid = this.dialogDetails.ID;

            this.formdata.zfjsgh = this.getZf();
            this.formdata.scfzyfjsgh = this.getScfzyf();
            this.formdata.shfzyfjsgh = this.getShfzyf();
            this.formdata.qtyfjsgh = this.getQtyf();
            this.formdata.xmmc = this.dialogDetails.XMMC;

            this.http.post(url, this.formdata).then((res) => {
                this.$message(res.message);
                if (res.status) {
                    this.getData();
                    this.handleChange2();
                    this.$emit('updatemessage')
                }
            });
        },
        setData() {
            this.dialogTableVisible = false;
            this.dialogTableVisible1 = false;
            this.dialogTableVisible2 = false;
            this.dialogTableVisible4 = false;
            this.dialogTableVisible6 = false;
            this.dialogTableVisible7 = false;
            this.activecollapse = "1";
            this.dialogTableVisible = true;
        },
        handleChange(value) {
            console.log(value);
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    },
    watch: {
        round() {
            this.setData();
            // this.getData();
            // this.handleChange2();
            // this.pagedata = {};
            // this.formdata = {};
        },
        data(e) {
            this.dialogDetails = e;
            console.log(this.dialogDetails);

            this.setData();
            this.getData();
        },
    },
};
</script>

<style lang="less"></style>
