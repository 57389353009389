<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划">
                                        <el-cascader style="width: 200px" size="medium" v-model="region" :options="regions" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-select v-model="xmlx" placeholder="请选择" style="width: 200px" @change="checkxmlx">
                                            <el-option label="货运枢纽" value="货运枢纽">货运枢纽</el-option>
                                            <el-option label="客运枢纽" value="客运枢纽">客运枢纽</el-option>
                                            <el-option label="乡镇运输服务站" value="乡镇运输服务站">乡镇运输服务站</el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目分类">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>

                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">

                                </span>
                                <h3 class="tit">枢纽站场列表</h3>
                            </div>
                            <div class="box-content newbox">
                                <el-table @row-dblclick="openDetails" :data="tableData" size="small" :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column prop="" label="操作" :width="70" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <!-- <i class="el-icon-postcard icons iconsyes" @click="openDetails(scope.row)"></i> -->
                                            <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>

                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="ID" label="分类" show-overflow-tooltip :width="200" sortable></el-table-column> -->
                                    <el-table-column prop="FL" label="分类" show-overflow-tooltip :width="200" sortable></el-table-column>
                                    <el-table-column prop="SSCS" label="所属城市" show-overflow-tooltip :width="100" sortable></el-table-column>
                                    <el-table-column prop="" label="项目名称" show-overflow-tooltip :width="200" sortable>
                                        <template slot-scope="scope">
                                            {{scope.row.XMMC||scope.row.ZHKYSNMC||scope.row.KYZMC}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="JSXZ" label="建设性质" show-overflow-tooltip :width="100" sortable></el-table-column>
                                    <el-table-column prop="ZDMJ" label="占地面积(亩)" show-overflow-tooltip :width="120" sortable></el-table-column>
                                    <el-table-column prop="ZTZ" label="总投资（万元）" show-overflow-tooltip :width="120" sortable></el-table-column>
                                    <el-table-column prop="" label="城市发展规划" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['城市发展规划'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['城市发展规划'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="选址意见书" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['选址意见书'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['选址意见书'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="用地预审意见" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['用地预审意见'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['用地预审意见'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="环境影响评价审批文件" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['环境影响评价审批文件'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['环境影响评价审批文件'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="节能审查意见" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['节能审查意见'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['节能审查意见'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="可行性研究报告批复" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['可行性研究报告批复（或核准或备案）'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['可行性研究报告批复（或核准或备案）'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="建设用地规划许可证" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['建设用地规划许可证'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['建设用地规划许可证'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="建设工程规划许可证" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['建设工程规划许可证'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['建设工程规划许可证'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="初步设计文件批复" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['初步设计文件批复（或核准或备案）'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['初步设计文件批复（或核准或备案）'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="资金申请报告" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['资金申请报告'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['资金申请报告'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="" label="建设用地批准书" width="100" align="center">
                                        <template slot-scope="scope">
                                            <i :class="scope.row['建设用地批准书'] ? 'el-icon-success' : 'el-icon-error'" :style="{color:scope.row['建设用地批准书'] ? '#67C23A':'#f56c6c'}" style="font-size:20px"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30,50,100,400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>

        <!-- 货运枢纽卡片 -->
        <div v-if="dialogTableVisible">
            <HYSN :data="dialogDetails" :round="dialogRound" :isedits="true" @updatemessage="updatemessages('dialogTableVisible')" />
        </div>
        <!-- 货运枢纽卡片 -->

        <!-- 客运枢纽卡片 -->
        <div v-if="dialogTableVisible2">
            <KYSN :data="dialogDetails" :round="dialogRound" :isedits="true" @updatemessage="updatemessages('dialogTableVisible2')" />
        </div>
        <!-- 客运枢纽卡片 -->

        <!-- 乡村服务站卡片 -->
        <div v-if="dialogTableVisible3">
            <XCFWZ :data="dialogDetails" :round="dialogRound" :isedits="true" @updatemessage="updatemessages('dialogTableVisible3')" />
        </div>
        <!-- 乡村服务站卡片 -->

    </div>
</template>
<script>
import { exportData } from "../../js/util/export.js"; //导出表格
import KYSN from "../Query/KYSN.vue";
import HYSN from "../Query/HYSN.vue";
import XCFWZ from "../Query/XCFWZ.vue";

export default {
    name: "Home",
    components: {
        KYSN,
        HYSN,
        XCFWZ,
    },
    data() {
        return {
            total: 0,
            baobiao: false,
            dialogRound: Math.round(Math.random() * 500),
            dialogTableVisibleMap: false,
            dialogTableVisibleData: {},
            xmlx: "货运枢纽",
            dialogTableVisible: false,
            dialogTableVisible2: false,
            dialogTableVisible3: false,
            dialogDetails: {},
            activecollapse: "1",
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索

            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedXmlx: [], //项目类型
            checkedJsxz: [],
            region: [""], //已选中行政区划
            /**查询条件 end*/

            xmxzShow: false, //项目性质查询条件显隐
            xmlxShow: false, //项目类型查询条件显隐
            checkList: [],
            checkAllXmxz: false,
            checkAllXmlx: false,
            checkAllJsxz: false,
            // xmxzList: ["续建项目", "新开工项目"],
            jsxzList: [
                { value: "续建项目", label: "续建项目", disabled: false },
                { value: "新开工项目", label: "新开工项目", disabled: false },
                { value: "谋划项目", label: "谋划项目", disabled: false },
            ],
            xmlxList: [
                {
                    value: "客运枢纽",
                    label: "客运枢纽",
                    disabled: false,
                },
                { value: "货运枢纽", label: "货运枢纽", disabled: false },
                {
                    value: "一般客运站",
                    label: "一般客运站",
                    disabled: false,
                },
                {
                    value: "多式联运型货运枢纽",
                    label: "多式联运型货运枢纽",
                    disabled: false,
                },
                {
                    value: "通用集散型货运枢纽",
                    label: "通用集散型货运枢纽",
                    disabled: false,
                },
                {
                    value: "物流园区集疏运",
                    label: "物流园区集疏运",
                    disabled: false,
                },
                {
                    value: "县（区）货运站场",
                    label: "县（区）货运站场",
                    disabled: false,
                },
                {
                    value: "县市客运站",
                    label: "县市客运站",
                    disabled: false,
                },
                {
                    value: "乡镇综合服务站",
                    label: "乡镇综合服务站",
                    disabled: false,
                },
            ],
            currentPage: 0, //当前页
            pageSize: 100, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            tableData: [], //请求列表
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 145;
        }, 100);
        this.getRegion();
        this.search();
    },
    methods: {
        getStatus(row) {
            // console.log(row);
            row["初步设计文件批复（或核准或备案）"] = "";
            row["可行性研究报告批复（或核准或备案）"] = "";
            row["城市发展规划"] = "";
            row["建设工程规划许可证"] = "";
            row["建设用地批准书"] = "";
            row["建设用地规划许可证"] = "";
            row["环境影响评价审批文件"] = "";
            row["用地预审意见"] = "";
            row["节能审查意见"] = "";
            row["资金申请报告"] = "";
            row["选址意见书"] = "";
            if (this.xmlx != "乡村运输服务站") {
                // console.log(row.CheckList && row.CheckList.length > 0);
                if (row.CheckList && row.CheckList.length > 0) {
                    row.CheckList.forEach((element, indexs) => {
                        row[element.SPSX] = element.PFDW;
                    });
                }
                // console.log(row);
                // var u =
                //     this.xmlx == "客运枢纽"
                //         ? "/api/Plan_passenger_card/getItemByKysnid?kysnid="
                //         : "/api/Plan_freight_card/getItemByHysnid?hysnid=";
                // this.http.post(u + row.ID, {}).then((r) => {
                //     if (r.data.checkList.length > 0) {
                //         r.data.checkList.forEach((element, indexs) => {
                //             row[element.spsx] = element.pfdw;
                //         });
                //     }
                // });
            }
        },
        updatemessages(e) {
            console.log(e, 4156456465);
            this.search();
        },
        checkxmlx(e) {
            if (this.xmlx == "乡镇运输服务站") {
                return true;
            }
            if (this.xmlx == "货运枢纽") {
                if ("县级客运站,综合客运枢纽".indexOf(e) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
            if (this.xmlx == "客运枢纽") {
                if (
                    "多式联运型货运枢纽（物流园区）,集散通用型货运枢纽（物流园区）,县（区）货运站场".indexOf(
                        e
                    ) >= 0
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        openDetails(row) {
            this.dialogRound = Math.round(Math.random() * 500);
            this.dialogDetails = row;
            console.log(this.xmlx);
            this.dialogTableVisible = false;
            this.dialogTableVisible2 = false;
            this.dialogTableVisible3 = false;
            if (this.xmlx == "货运枢纽") this.dialogTableVisible = true;
            if (this.xmlx == "客运枢纽") this.dialogTableVisible2 = true;
            if (this.xmlx == "乡镇运输服务站") this.dialogTableVisible3 = true;
        },
        exportData() {
            exportData("Plan_hinge", this.postData());
        },
        getRegion() {
            var postData = { ssds: "" };
            this.http
                .post("/api/Plan_hinge/getRegion", postData)
                .then((res) => {
                    //遍历地市
                    res.forEach((v) => {
                        var item = {};
                        //遍历区县
                        for (var i = 0; i < v.length; i++) {
                            var qx = v[i];
                            //设置地市名称
                            item.value = qx.szds;
                            item.label = qx.szds;
                        }
                        this.regions.push(item);
                    });
                });
        },
        //查询
        search() {
            this.handleChanges(this.xmlx, 1);
        },
        postData() {
            var ssds = "";
            if (this.region.length > 0) {
                ssds = this.region[0];
            }

            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //所属地市
            var query_ssds = {
                Name: "SZDS",
                Value: ssds,
                DisplayType: "Equal",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //项目性质
            var query_xmxz = {
                Name: "XMXZ",
                Value: this.checkedXmxz.join(","),
                DisplayType: "checkbox",
            };

            //项目类型
            var query_xmlx = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: "XH",
                Order: "asc",
                wheres: JSON.stringify([
                    query_xmmc,
                    query_ssds,
                    query_xmnd,
                    query_xmxz,
                    query_xmlx,
                    query_jsxz,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.region = [""];
            this.xmmc = "";
            this.checkedJsxz = [];
            this.checkedXmlx = [];
            this.xmlx = "货运枢纽";
        },

        handleChanges(value, type) {
            if (!type) {
                this.checkedXmlx = [];
                this.currentPage = 1;
            }
            // console.log(value);
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            var url = "/api/Plan_freight/GetPageData";
            if (value == "客运枢纽") {
                query_xmmc = {
                    Name: "ZHKYSNMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                url = "/api/Plan_passenger/GetPageData";
            }
            if (value == "乡镇运输服务站") {
                query_xmmc = {
                    Name: "KYZMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                url = "/api/plan_township_transportation/GetPageData";
            }
            var sscs = "";
            if (this.region.length > 0) {
                sscs = this.region[0];
            }

            var query_ssds = {
                Name: "SSCS",
                Value: sscs,
                DisplayType: "Equal",
            };
            var query_fl = {
                Name: "FL",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            var formdata = {
                page: this.currentPage,
                rows: 30,
                Sort: "XH",
                Order: "asc",
                wheres: JSON.stringify([
                    query_fl,
                    query_ssds,
                    query_xmmc,
                    query_jsxz,
                ]),
            };
            this.http
                .post(url, formdata, "正在请求数据，请稍候...")
                .then((res) => {
                    if (res.status == 0) {
                        //临时
                        res.rows.forEach((item) => {
                            this.getStatus(item);
                        });
                        //临时
                        console.log(res, 456);

                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
        },

        handleChange(value) {
            // console.log(value);
            if (value == "高速公路" || value == "普通国道" || value == "G331") {
                // this.tableHeight = document.body.offsetHeight - 750;
                this.xmxzShow = true;
                this.xmlxShow = false;
            }
            if (value == "普通省道") {
                // this.tableHeight = document.body.offsetHeight - 750;
                this.xmxzShow = false;
                this.xmlxShow = true;
            }
            if (value == "") {
                this.xmxzShow = false;
                this.xmlxShow = false;
                // this.tableHeight = document.body.offsetHeight - 713;
            }
            // this.search();
        },
        handleCheckAllXmxzChange(val) {
            this.checkedXmxz = val ? this.xmxzList : [];
            this.isIndeterminateXmxz = false;
        },
        handleCheckAllJsxzChange(val) {
            this.checkedJsxz = val ? this.jsxzList : [];
            this.isIndeterminateJsxz = false;
        },
        handleCheckAllXmlxChange(val) {
            this.checkedXmlx = [];
            // this.checkedXmlx = val ? this.xmlxList : [];
            if (val) {
                if (this.xmlx == "乡镇运输服务站") {
                    this.checkedXmlx = [];
                }
                if (this.xmlx == "货运枢纽") {
                    this.checkedXmlx = [
                        "多式联运型货运枢纽（物流园区）",
                        "集散通用型货运枢纽（物流园区）",
                        "县（区）货运站场",
                    ];
                }
                if (this.xmlx == "客运枢纽") {
                    this.checkedXmlx = ["县级客运站", "综合客运枢纽"];
                }
            } else {
                this.checkedXmlx = [];
            }
            this.isIndeterminateXmlx = false;
        },
        handleCheckedXmxzChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.checkAllXmxz = checkedCount === this.xmxzList.length;
            this.isIndeterminateXmxz =
                checkedCount > 0 && checkedCount < this.xmxzList.length;
        },
        handleCheckedJsxzChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.checkAllJsxz = checkedCount === this.jsxzList.length;
            this.isIndeterminateJsxz =
                checkedCount > 0 && checkedCount < this.jsxzList.length;
        },
        handleCheckedXmlxChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.checkAllXmlx = checkedCount === this.xmlxList.length;
            this.isIndeterminateXmlx =
                checkedCount > 0 && checkedCount < this.xmlxList.length;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>

<style lang="less">
.newbox {
    .el-table__body-wrapper {
        height: calc(100% - 65px) !important;
    }
}
.mapdialog {
    .el-dialog {
        width: 80%;
        overflow: hidden;
    }
}
.mapdialog1 {
    height: 100%;
    overflow: hidden;
    .el-dialog {
        height: 80%;
    }
}
</style>
