<template>
    <div>
        <el-dialog :title="dialogDetails.KYZMC" :visible.sync="dialogTableVisible" class="mapdialog" width="50%">

            <div class="mb15" v-if="isedits">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2,submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">客运站名称</th>
                            <td colspan="3">{{ dialogDetails.KYZMC }}</td>
                        </tr>
                        <tr>
                            <th width="150">位置</th>
                            <td>{{ dialogDetails.WZ }}</td>
                            <th width="150">建设性质</th>
                            <td>{{ dialogDetails.JSXZ }}</td>
                        </tr>
                        <tr>
                            <th>总投资（万元）</th>
                            <td>{{ dialogDetails.ZTZ }}</td>
                            <th>站场级别</th>
                            <td>{{ dialogDetails.ZCJB }}</td>
                        </tr>
                        <tr>
                            <th>占地面积(平方米)</th>
                            <td>{{ dialogDetails.ZDMJ }}</td>
                            <th>建筑面积(平方米)</th>
                            <td>{{ dialogDetails.JZMJ }}</td>
                        </tr>
                        <tr>
                            <th>设计发送量(人/日)</th>
                            <td>{{ dialogDetails.SJFSL }}</td>
                            <th>计划实施年限</th>
                            <td>{{ dialogDetails.JHSSNX }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow&&isedits">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                <div class="mycard-lg">
                    <el-form ref="formdata" :model="formdata">
                        <table class="table">
                            <tr>
                                <th width="150">客运站名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.KYZMC" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th width="150">位置</th>
                                <td>
                                    <el-input v-model="formdata.WZ" disabled></el-input>
                                </td>
                                <th width="150">建设性质</th>
                                <td>
                                    <!-- <el-input v-model="formdata.JSXZ" disabled></el-input> -->
                                     <el-select v-model="formdata.JSXZ" placeholder="请选择" style="width:100%">
                                        <el-option label="新建" value="新建"></el-option>
                                        <el-option label="改建" value="改建"></el-option>
                                        <el-option label="扩建" value="扩建"></el-option>
                                        <el-option label="改扩建" value="改扩建"></el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <th>总投资（万元）</th>
                                <td>
                                    <el-input v-model="formdata.ZTZ"></el-input>
                                </td>
                                <th>站场级别</th>
                                <td>
                                    <el-input v-model="formdata.ZCJB"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>占地面积(平方米)</th>
                                <td>
                                    <el-input v-model="formdata.ZDMJ"></el-input>
                                </td>
                                <th>建筑面积(平方米)</th>
                                <td>
                                    <el-input v-model="formdata.JZMJ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>设计发送量(人/日)</th>
                                <td>
                                    <el-input v-model="formdata.SJFSL"></el-input>
                                </td>
                                <th>计划实施年限</th>
                                <td>
                                    <el-input v-model="formdata.JHSSNX"></el-input>
                                </td>
                            </tr>
                        </table>
                    </el-form>
                </div>
            </div>

            <!-- <div class="titles">备注：</div> -->
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["data", "round","isedits"],
    name: "",
    components: {},
    data() {
        return {
            formdata: {
                KYZMC: "",
                WZ: "",
                JSXZ: "",
                ZTZ: "",
                ZCJB: "",
                ZDMJ: "",
                JZMJ: "",
                SJFSL: "",
                JHSSNX: "",
            },
            dialogTableVisible: false,
            dialogDetails: {},
            activecollapse: "1",
            isedit: false,
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
        };
    },
    mounted() {
        this.dialogDetails = this.data;
        this.setData();
    },
    methods: {
        submit() {
            this.http
                .post(
                    "/api/Plan_township_transportation/UpdateTownship",
                    this.formdata
                )
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.dialogDetails = this.formdata;
                        this.handleChange2();
                        this.$emit("updatemessage");
                    }
                });
        },
        setData() {
            this.dialogTableVisible = false;
            this.activecollapse = "1";
            this.dialogTableVisible = true;
            this.formdata = JSON.parse(JSON.stringify(this.dialogDetails));
        },
        handleChange(value) {
            console.log(value);
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
    },
    watch: {
        round() {
            this.setData();
            console.log(1);
            this.isedit = false;
            this.handleChange2();
        },
        data(e) {
            console.log(1123);
            this.dialogDetails = e;
            this.setData();
            this.handleChange2();
        },
    },
};
</script>

<style lang="less">
</style>